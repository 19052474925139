<template>
  <div class="algorithms">
    <UiLoader v-model="loader" />
    <UiNotification v-model="showNotification" :message="message" />
    <Table
        v-if="!loader && getAlgorithms.length"
        :title="$t('algorithms')"
        :descriptionList="[
            {title: this.$t('type-2'), width: 400},
            {title: this.$t('number-of-parameters'), width: 180, paddingLeft: 0, textAlign: 'center'},
            {title: this.$t('possibility-of-inversion'), width: 200, paddingLeft: 0, textAlign: 'center'}
        ]"
        :actionText="$t('add-type')"
        :data="getAlgorithms"
        hideEditIcon
        isAlgorithm
        ref="table"
        @addRowField="addNewAlgorithms"
        @changeRowField="editItem"
        @deleteRowField="deleteItem"
    />
  </div>
</template>

<script>
import UiLoader from "@/components/ui/UiLoader.vue";
import UiNotification from "@/components/ui/UiNotification.vue";
import Table from "@/components/ui/UiTable.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RegionFormulas",
  components: {Table, UiNotification, UiLoader},

  data () {
    return {
      year: {},
      loader: true,
      showNotification: false,
      message: '',
      isEmpty: false
    }
  },

  methods: {
    ...mapActions(['algorithms', 'addAlgorithms', 'deleteAlgorithms', 'editAlgorithms']),

    loadPage(message) {
      this.algorithms().then(() => {
        setTimeout(() => {
          this.showNotification = true
          this.loader = false;
          this.message = message
        }, 500)
      })
    },

    addNewAlgorithms (data) {
      this.loader = true
      const payload = {
        name: data.name,
        code: 'code',
        canInversion: data.algorithmInversion,
        parmNumber: data.algorithmNumber
      }
      this.addAlgorithms(payload).then(() => {
        this.loadPage(this.$t('algorithm-successfully-added'))
      })
    },

    deleteItem (data) {
      this.loader = true
      this.deleteAlgorithms({id: data.id}).then(() => {
        this.loadPage(this.$t('algorithm-successfully-deleted'))
      })
    },

    editItem(data) {
      this.loader = true
      const payload = {
        name: data.name,
        code: "code",
        canInversion: data.algorithmInversion,
        parmNumber: data.algorithmNumber,
        id: data.id
      }
      this.editAlgorithms(payload).then(() => {
        this.loadPage(this.$t('algorithm-successfully-updated'))
      })
    }
  },

  computed: {
    ...mapGetters(['getAlgorithms'])
  },

  mounted () {
    this.algorithms().then(() => {
      this.loader = false;
    })
  }
}
</script>

<style lang="scss" scoped>
.algorithms {
  width: 100%;
}
</style>